import React from "react"

export function SectionTitle(props: { children: string }) {
  return (
    <h1 className="sm:text-xxl text-xl2 font-bold font-body text-left text-blue-night mb-3">
      {props.children}
    </h1>
  )
}

export function PageTitle(props: { children: React.ReactNode }) {
  return (
    <h1 className="px-4 sm:text-xxxl text-xl3 font-bold font-body text-center text-blue-night mb-3">
      {props.children}
    </h1>
  )
}
