import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  //  children?: React.ReactNode,
  title: string;
  description?: string;
}

const defaultTitle = "cokhach.vn";
const defaultDescription = "cokhach.vn là công cụ marketing toàn diện dành riêng cho ngành Bất động sản Việt Nam, hỗ trợ tạo và quản lý quảng cáo, tối ưu chuyển đổi một cách dễ dàng.";
export function Meta(props: Props) {
  const image = "https://cdn.cokhach.vn/pimage/home-page/cokhach_ogp.png";
  const title = props.title || defaultTitle;
  const description = props.description || defaultDescription;
  return (
    <Helmet>
      <html lang="vi" />
      <title>{title}</title>
      <meta
        name="keywords"
        content="cokhach,cokhach.vn,marketing,Bất động sản,landing page,website,Google Ads,Facebook Ad" />
      <meta name="description" content={description} />
      <meta
        name="image"
        content="https://cdn.cokhach.vn/pimage/home-page/cokhachvn-thumbnail.png?v2"
      />
      <meta property="og:site_name" content={"cokhach.vn"} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content="cokhach.vn - Thêm Khách Nét - Giảm Chi Phí - Không đòi hỏi Kỹ Năng" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}
